import * as styles from './CardSymbol.module.scss'

import React from 'react'
import { useMemo } from 'react'

export type CardSymbolType =
  | 'w'
  | 'u'
  | 'b'
  | 'r'
  | 'g'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '2/b'
  | '2/g'
  | '2/r'
  | '2/u'
  | '2/w'
  | 'c'
  | 's'
  | 'x'
  | 't'
  | 'q'
  | 'e'
  | 'b/g'
  | 'b/p'
  | 'b/r'
  | 'g/p'
  | 'g/u'
  | 'g/w'
  | 'r/g'
  | 'r/p'
  | 'r/w'
  | 'u/b'
  | 'u/p'
  | 'u/r'
  | 'w/b'
  | 'w/p'
  | 'w/u'
  | 'b/g/p'
  | 'b/r/p'
  | 'g/u/p'
  | 'g/w/p'
  | 'r/g/p'
  | 'r/w/p'
  | 'u/b/p'
  | 'u/r/p'
  | 'w/b/p'
  | 'w/u/p'

interface Props {
  children: CardSymbolType
}

const CardSymbol: React.FC<Props> = (props) => {
  const { children } = props

  const symbolName = useMemo(
    () => children.replaceAll('/', '').toLowerCase(),
    [children],
  )

  return (
    <img
      src={`/symbols/${symbolName}.svg`}
      alt={`{${children.toUpperCase()}}`}
      className={styles.container}
      draggable={false}
    />
  )
}

export default CardSymbol
