import * as styles from './PodcastPlainTextNotesTemplate.module.scss'

import React from 'react'
import { PageProps, graphql } from 'gatsby'

import PlainMarkdown from 'components/markdown/PlainMarkdown'

interface Props extends PageProps<Queries.PodcastPlainTextNotesTemplateQuery> {
  pageContext: any
}

export const PodcastPlainTextNotesTemplate: React.FC<Props> = (props) => {
  const { podcast } = props.data

  if (podcast?.frontmatter?.episodeSlug == null) {
    return null
  }

  return (
    <div className={styles.container}>
      {podcast.frontmatter.cards?.groups != null && (
        <a
          href={`http://luckypaper.co/podcast/${podcast.frontmatter.episodeSlug}/cards`}
        >
          View all cards mentioned in this episode
        </a>
      )}
      <PlainMarkdown>{podcast.body}</PlainMarkdown>
      <p>Timestamps</p>
      <ul>
        <li>0:00 - Intro</li>
        {podcast.frontmatter.timestamps?.map((timestamp) => (
          <li key={timestamp?.time}>
            {timestamp?.time} - {timestamp?.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const query = graphql`
  query PodcastPlainTextNotesTemplate($episodeOrder: Int!) {
    podcast: mdx(frontmatter: { episodeOrder: { eq: $episodeOrder } }) {
      body
      frontmatter {
        title
        excerpt
        formattedDate: date(formatString: "MMMM Do, YYYY")
        date
        episodeNumber
        episodeSlug
        timestamps {
          time
          label
        }
        cards {
          groups {
            heading
            subheading
          }
        }
        annotationCredit {
          name
          link
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  const { podcast } = props.data

  if (podcast?.frontmatter == null) {
    return null
  }

  return (
    <>
      <meta name="robots" content="noindex, nofollow" />
    </>
  )
}

export default PodcastPlainTextNotesTemplate
