import * as styles from './ManaCost.module.scss'

import React, { useMemo } from 'react'
import classNames from 'classnames'

import CardSymbol, { CardSymbolType } from 'components/CardSymbol'

interface Props {
  children: string
  block?: boolean
}

const ManaCost: React.FC<Props> = (props) => {
  const { children, block = false } = props

  const symbols = useMemo(() => {
    if (children[0] === '{') {
      return children
        .slice(1, children.length - 1)
        .toLowerCase()
        .split('}{') as CardSymbolType[]
    }
    return children.toLowerCase().split('') as CardSymbolType[]
  }, [children])

  return (
    <div
      className={classNames(styles.container, {
        [styles.inline]: !block,
      })}
    >
      {symbols.map((symbol, index) => (
        <CardSymbol key={index}>{symbol}</CardSymbol>
      ))}
    </div>
  )
}

export default ManaCost
