import * as styles from './Blockquote.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  children: string
  credit?: string
  creditLink?: string
  placement: 'left' | 'right' | 'center'
}

const Blockquote: React.FC<Props> = (props) => {
  const { children, credit, creditLink, placement } = props

  return (
    <blockquote
      className={classNames(styles.container, {
        [styles.left]: placement === 'left',
        [styles.right]: placement === 'right',
        [styles.center]: placement === 'center',
      })}
    >
      <div className={styles.content}>&ldquo;{children}&rdquo;</div>
      {credit && (
        <footer>
          <cite>
            —{' '}
            {creditLink ? (
              <a href={creditLink} className={styles.link}>
                {credit}
              </a>
            ) : (
              credit
            )}
          </cite>
        </footer>
      )}
    </blockquote>
  )
}

export default Blockquote
