import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import HighlightBlock from 'components/article-elements/HighlightBlock'

import CardSymbol from 'components/CardSymbol'
import ManaCost from 'components/ManaCost'

import Blockquote from './Blockquote'

interface Props {
  children: any
  components?: Record<string, React.ComponentType<any>>
}

const globalComponents: Record<string, React.ComponentType<any>> = {
  // Custom Components
  blockquote: Blockquote,
  'highlight-block': HighlightBlock,
  'card-symbol': CardSymbol,
  mana: ManaCost,
}

const PlainMarkdown: React.FC<Props> = (props) => {
  const { children, components } = props

  return (
    <MDXProvider
      components={{ ...globalComponents, ...(components ? components : {}) }}
    >
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  )
}

export default PlainMarkdown
