import * as styles from './HighlightBlock.module.scss'

import React from 'react'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  link?: string
}

const HighlightBlock: React.FC<Props> = (props) => {
  const { children, link } = props

  return (
    <div className={styles.wrapper}>
      {link ? (
        <a
          href={link}
          className={classNames(styles.highlightBlock, styles.link)}
        >
          {children}
        </a>
      ) : (
        <div className={styles.highlightBlock}>{children}</div>
      )}
    </div>
  )
}

export default HighlightBlock
